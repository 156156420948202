<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <div>
                    <el-row :gutter="12">
                        <el-col :lg="8" :md="12" :sm="24" v-for="(menu ,k) in menus" :key="k">
                            <el-card shadow="hover" class="box-card"
                                     v-if="userPermissions.indexOf(menu.permission_name) !== -1">
                                <el-link type="primary" @click="goToPage(menu.url)">
                                  <i :class="`iconfont ${menu.icon}`" style="line-height:0!important;"></i>
                                  &nbsp;&nbsp;{{ menu.display_name }}&nbsp;&nbsp;
                                </el-link>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'DataIndex',
  components: { PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      menus: [
        { icon: 'hongren', display_name: '红人管理', url: '/artist', permission_name: 'artist_list' },
        { icon: 'zhangfen', display_name: '涨粉数据', url: '/trend/enter', permission_name: 'trend_list' },
        { icon: 'yingshou', display_name: '营收数据', url: '/revenue/enter', permission_name: 'revenue_list' },
        { icon: 'zhibo', display_name: '直播数据', url: '/live_data/enter', permission_name: 'live_data_create' },
        // { icon: 'dianpu', display_name: '店铺数据', url: '/shop_data/enter', permission_name: 'shop_data_create' },
        {
          icon: 'hongren',
          display_name: '红人分成',
          url: '/artist_commission',
          permission_name: 'artist_divide_list'
        },
        {
          icon: 'zhangfen',
          display_name: '人工成本',
          url: '/labor_cost',
          permission_name: 'kol-analysis-setting-list'
        },
        { icon: 'el-icon-connection', display_name: '返点费用', url: '/rebate', permission_name: 'rebate_list' },
        { icon: 'el-icon-bank-card', display_name: 'MCN推广', url: '/mcn_promotion', permission_name: 'mcn_promote_list' },
        { icon: 'el-icon-set-up', display_name: '直播投放金', url: '/live_funding', permission_name: 'live_put_list' },
        { icon: 'el-icon-set-up', display_name: '账号余额', url: '/balance', permission_name: 'platform_balance' }
      ]
    }
  },
  methods: {
    goToPage(routeName) {
      this.$router.push({ path: routeName })
    }
  }
}
</script>

<style scoped>
    .box-card {
        margin-bottom: 10px;
    }

    .dev {
        color: #909399;
    }

    .dev::after {
        content: '(开发中...)';
    }
</style>
