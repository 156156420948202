<template>
  <div>
    <router-view v-show="$route.matched.length == 3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length == 2">
      <div class="main-page-content">
        <div>
          <el-row :gutter="12">
            <el-col :lg="8" :md="12" :sm="24" v-for="(menu, k) in menus" :key="k">
              <el-card shadow="hover" class="box-card" v-if="userPermissions.indexOf(menu.permission_name) !== -1">
                <el-link type="primary" @click="goToPage(menu.url)">
                  <i :class="`iconfont ${menu.icon}`"></i>
                  &nbsp;&nbsp;{{ menu.display_name }}&nbsp;&nbsp;
                </el-link>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'RevenueIndex',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        { icon: 'icon-yingshou', display_name: '营收数据-录入', url: '/revenue/enter', permission_name: 'artist_list' },
        { icon: 'icon-yingshoushujlur', display_name: '营收数据-列表', url: '/revenue/data', permission_name: 'trend_list' },
        { icon: 'icon-yingshoushujlur', display_name: '总营收一览', url: '/revenue/index', permission_name: 'revenue_list' },
        { icon: 'icon-zhangfen', display_name: '实时订单排行', url: '/revenue/statistics', permission_name: 'trend_list' },
      ],
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
  },
  watch: {},
  methods: {
    goToPage(routeName) {
      this.$router.push({ path: routeName })
    },
    // 数据录入
    pageChildren(route) {
      this.$router.push(this.$route.path + '/' + route)
    },
  },

  mounted() {},
}
</script>

<style lang="stylus">
.box-card {
  margin-bottom 10px;
}
</style>
