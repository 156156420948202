<template>
  <div>
    <div class="main-page-content">
      <el-row>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <i class="iconfont header-index-trigger" :title='`${isCollapse?"展开侧边栏":"隐藏侧边栏"}`'
               :class="[isCollapse?'icon-menu-fold':'icon-menu-unfold']"
               @click="showSidebar"></i> &nbsp;
            <span class='title'>我的菜单</span>
            <el-link type="primary" style="float: right" @click="handleNavType">
              {{ navType === 'old' ? '简约版' : '新版' }}
            </el-link>
          </div>
          <allMenus :navType="navType"></allMenus>
          <!--           :disabled="!(v['permission_name'] ? userPermissions.indexOf(v['permission_name']) === -1 : false)"-->
        </el-card>
      </el-row>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

import allMenus from '@/pages/user/components/allMenus.vue'

export default {
  name: 'DataIndex',
  components: { allMenus, PageHeaderLayout },
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'isCollapse'])
  },
  data() {
    return {
      navType: 'new'
    }
  },
  methods: {
    showSidebar() {
      //
      this.$store.commit('changeCollapse')
    },
    handleNavType() {
      this.navType = this.navType === 'old' ? 'new' : 'old'
      localStorage.setItem('allNavType', this.navType)

    }

  },
  mounted() {
    let navType = localStorage.getItem('allNavType')
    if (navType) {
      this.navType = navType
    }
    // this.navMenus = this.navMenus.slice(1, this.length)
  }
}
</script>
<style scoped>
.box-card {
  margin-bottom: 10px;
  overflow: hidden;
}

.dev {
  color: #909399 !important;
}

.dev::after {
  content: '(开发中...)';
}

.navBox {

}
</style>
